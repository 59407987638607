export const breakpoints = {
  none: '0px',
  xxs: '435px', // small
  xs: '600px', // small-medium
  sm: '768px', // medium
  md: '900px', // medium-large;
  lg: '1024px', // large
  xl: '1280px', // xl
  xxl: '1440px', // xxl
} as const;

export type Breakpoint = keyof typeof breakpoints;

export function getMediaQueryForBreakpoint(name: Breakpoint) {
  if (!name || breakpoints[name] === undefined) {
    return `(min-width: ${breakpoints.none})`;
  }

  return `(min-width: ${breakpoints[name]})`;
}

export const breakpointOrder = Object.keys(breakpoints).reduce((acc, key, index) => {
  acc[key] = index;
  return acc;
}, {} as Record<string, number>);
